:root {
    --title-font-family: 'IBM Plex Mono', monospace;
    --title-font-size: 36px;
    --title-font-style: italic;
    --title-font-weight: 300;
    --title-line-height: 46.8px;
    --title-text-align: left;
  
    --button-font-family: 'IBM Plex Sans', sans-serif;
    --button-font-size: 14px;
    --button-font-weight: 300;
    --button-line-height: 26px;
    --button-text-align: left;
  }
  
  .title {
    font-family: var(--title-font-family);
    font-size: var(--title-font-size);
    font-style: var(--title-font-style);
    font-weight: var(--title-font-weight);
    line-height: var(--title-line-height);
    text-align: var(--title-text-align);
  }
  
  .button {
    font-family: var(--button-font-family);
    font-size: var(--button-font-size);
    font-weight: var(--button-font-weight);
    line-height: var(--button-line-height);
    text-align: var(--button-text-align);
    background-color: white;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: none;
    border-bottom: none;
    padding: 10px 40px;
    margin-right: -1px; /* To avoid double border */
    height: 60px;
  }

  
  .button:last-child {
    border-right: none; /* Remove right border for the last button */
    margin-right: 0; /* Remove right margin for the last button */
  }

  .resources-row {
    border-bottom: 1px solid black;
    font-family: 'IBM Plex Serif';
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
    height: 60px;
  }